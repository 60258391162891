.page_container {
  min-height: 100vh;
  padding: 1rem;
  background-color: #f0f2f5;
}

.dashboardContainer {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-height: 100vh;
}

.searchbar {
  width: 100% !important;
  margin: 0 auto;
}

@media screen and (min-width: 1040px) {
  .searchbar {
    width: 50% !important;
  }
}

.page_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  padding: 10px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
}

.page_title {
  font-size: 2rem;
  margin-bottom: 2rem;
  line-height: 1;
}

.button_group {
  display: flex;
  gap: 1rem;
}
