@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Righteous&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Righteous&display=swap");
*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

a:link,
a:visited {
  text-decoration: none;
  color: inherit;
}

body {
  font-family: "Montserrat", sans-serif;
  line-height: 1.6;
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Righteous", sans-serif;
}

img {
  width: 100%;
  height: auto;
}

/* Custom CSS for ant components */
.login_form .ant-form-item-control-input-content {
  display: flex;
}

.login_form .ant-input-affix-wrapper {
  border-radius: 4px;
}

.login_form .ant-btn {
  width: 100%;
  border-radius: 4px;
}
