.login_container {
  min-height: 100vh;
  padding-bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background: #fff;
}

@media screen and (min-width: 390px) and (max-width: 480px) {
  .login_container {
    justify-content: flex-start;
    padding-top: 100px;
  }
}

@media screen and (min-width: 767px) {
  .login_container {
    justify-content: center;
  }
}

@media screen and (min-width: 1040px) {
  .login_container {
    padding-bottom: 40px;
    padding-top: 0;
  }
}

.login_form_container {
  width: 100%;
  padding: 1rem;
}

.login_logo {
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
}

@media screen and (min-width: 767px) {
  .login_form_container {
    width: 70%;
  }
}

@media screen and (min-width: 1040px) {
  .login_logo {
    margin-bottom: 1rem;
  }

  .login_form_container {
    width: 50%;
  }
}

.login_logo img {
  width: 128px;
  height: auto;
  object-fit: contain;
  border: 10px solid #f2f2f2;
  background-color: #f0f2f5;
  border-radius: 50%;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.login_card {
  backdrop-filter: blur(10px);
  background-color: rgba(255, 255, 255, 0.3);
  box-shadow: 0 8px 32px rgba(31, 38, 135, 0.37);
  border-radius: 10px;
  padding: 2rem 0 0 0;
  position: relative;
}

.login_card::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url(../../assets/images/tc-bg-image.png);
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 10px;
  z-index: -1;
}

.login_title {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #0f856a);
  background-size: 400% 400%;
  animation: gradient 15s ease infinite;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding: 7px;
  text-align: center;
  font-size: 14px;
  font-family: "Righteous", sans-serif;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 0.5px;
  color: #fff;
  z-index: 9;
}

.login_form {
  padding: 2rem;
  background: rgba(255, 255, 255, 0.25);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  border-radius: 3px;
  border: 1px solid rgba(255, 255, 255, 0.18);
}

.login_button {
  margin-top: 10px;
  margin-bottom: -20px;
}

.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 10px;
  width: 100%;
  background: #ededed;
  color: #3f3f3f;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: center;
  margin-bottom: 0;
}

.footer p {
  font-size: 12px;
  padding: 5px;
}

@media screen and (min-width: 767px) {
  .footer {
    padding-bottom: 5px;
  }

  .footer p {
    font-size: 12px;
    padding: 5px 10%;
    line-height: 1.2;
  }
}

@media screen and (min-width: 1040px) {
  .footer {
    padding-bottom: 0;
  }

  .footer p {
    font-size: 12px;
    line-height: 0;
  }
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
