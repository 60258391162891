.header {
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
}

.headerContent {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 1rem;
  max-width: 1200px;
  margin: 0 auto;
}

.logo {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.searchBar {
  display: flex;
  align-items: center;
  background-color: white;
  border-radius: 20px;
  padding: 0.5rem 1rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.searchInput {
  border: none;
  outline: none;
  background-color: transparent;
  margin-left: 0.5rem;
}

.userInfo {
  display: flex;
  align-items: center;
}

.userName {
  margin-right: 0.5rem;
}

.icon {
  cursor: pointer;
  margin-left: 1rem;
}

@media (max-width: 768px) {
  .searchBar {
    display: none;
  }

  .mobileSearchIcon {
    display: block;
  }
}

@media (min-width: 769px) {
  .mobileSearchIcon {
    display: none;
  }
}
